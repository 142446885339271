import {
  Row,
  Col,
  Button,
  Divider,
  Avatar,
  Tooltip,
  Pagination,
  Spin,
  Form,
  Input,
  Empty,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  UserOutlined,
  InboxOutlined,
  FilterOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import axios from "axios";
import dayjs from "dayjs";
import { FiClock, FiCalendar, FiMapPin } from "react-icons/fi";

import spnLogo from "../../assets/images/SPN-grey-logo.svg";
import apedtLogo from "../../assets/images/APEDT-grey-logo.svg";
import config from "../../utils/config.js";
import Create from "../../components/Program/Create.js";
import Update from "../../components/Program/Update.js";
import Delete from "../../components/Program/Delete.js";

import "./Program.css";
import "react-quill/dist/quill.snow.css";
import Search from "antd/es/input/Search";
import endpoints from "../../utils/endpoints.js";

function Program() {
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedData, setSelectedData] = useState({});

  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(5);

  const [form] = Form.useForm();

  useEffect(() => {
    handleGetData();
  }, []);

  function handleGetData() {
    setIsLoading(true);
    axios
      .get(endpoints.program.read)
      .then((res) => {
        let auxData = res.data[0];
        const uniqueIds = [];

        const unique = auxData.filter((element) => {
          const isDuplicate = uniqueIds.includes(element.id);

          if (!isDuplicate) {
            uniqueIds.push(element.id);

            return true;
          }

          return false;
        });

        setData(unique);
        handlePrepareData(unique);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }

  function handlePrepareData(data) {
    setIsLoading(true);

    let auxArray = [];

    for (let i = 0; i < data.length; i++) {
      let arrayData = data[i];

      let aux = {
        key: i,
        title: arrayData.title,
        description: (
          <div dangerouslySetInnerHTML={{ __html: arrayData.description }} />
        ),
        local: arrayData.local,
        date: dayjs(arrayData.date).format("YYYY-MM-DD"),
        hourStart: `${arrayData.hour_start.split(":")[0]}:${
          arrayData.hour_start.split(":")[1]
        }`,
        hourEnd: `${arrayData.hour_end.split(":")[0]}:${
          arrayData.hour_end.split(":")[1]
        }`,
        location_name: arrayData.location_name,
        actions: (
          <div className="actions-buttons-div">
            <EditOutlined
              className="edit-action"
              onClick={() => handleOpenUpdate(data[i])}
            />
            <DeleteOutlined
              className="delete-action"
              onClick={() => handleOpenDelete(data[i])}
            />
          </div>
        ),
      };

      auxArray.push(aux);
    }

    setTableData(auxArray);
    setIsLoading(false);
  }

  function handleOpenCreate() {
    setIsOpenCreate(true);
  }

  function handleCloseCreate() {
    setIsOpenCreate(false);
    handleGetData();
  }

  function handleChangePage(e) {
    setCurrentPage(e);
    if (e <= 1) {
      setMinValue(0);
      setMaxValue(itemsPerPage);
    } else {
      let newMinValue = itemsPerPage * (e - 1);
      let newMaxValue = newMinValue + itemsPerPage;
      setMinValue(newMinValue);
      setMaxValue(newMaxValue);
    }
  }

  function handleOpenUpdate(data) {
    setSelectedData(data);
    setIsOpenUpdate(true);
  }

  function handleCloseUpdate() {
    setIsOpenUpdate(false);
    handleGetData();
  }

  function handleOpenDelete(data) {
    setSelectedData(data);
    setIsOpenDelete(true);
  }

  function handleCloseDelete() {
    setSelectedData({});
    setIsOpenDelete(false);
    handleGetData();
  }

  function handleSearch(_, allValues) {
    let value = allValues.search;
    const newData = data.filter((item) =>
      item.title.toLowerCase().includes(value.toLowerCase())
    );

    handlePrepareData(newData);
  }

  return (
    <div className="dashboard_page">
      <Create open={isOpenCreate} close={handleCloseCreate} />
      <Update
        data={selectedData}
        open={isOpenUpdate}
        close={handleCloseUpdate}
      />
      <Delete
        data={selectedData}
        open={isOpenDelete}
        close={handleCloseDelete}
      />
      <Row className="title_row mb-20 dashboard_title_row">
        <Col span={12} className="d-flex jc-start ai-center">
          <h3 className="mt-0 mb-0">Program</h3>
        </Col>
        <Col span={12}>
          <Row className="d-flex jc-end ai-center">
            <Form form={form} onValuesChange={handleSearch} className="mr-10">
              <Form.Item name="search" className="m-0">
                <Input
                  style={{ borderRadius: 0 }}
                  size="large"
                  placeholder="Search..."
                />
              </Form.Item>
            </Form>
            <Button className="mr-10" size="large" onClick={handleGetData}>
              <ReloadOutlined />
            </Button>
            <Button type="primary" size="large" onClick={handleOpenCreate}>
              Create
            </Button>
          </Row>
        </Col>
      </Row>
      <Divider />
      <Spin spinning={isLoading}>
        <Row className="dashboard_content_row">
          <Col span={24}>
            {tableData.length > 0 ? (
              <div className="d-flex flex-column jc-sb h-100">
                <div>
                  {tableData.slice(minValue, maxValue).map((item) => {
                    return (
                      <Row className="program_item">
                        <Col span={24}>
                          <Row gutter={[24]}>
                            <Col
                              span={11}
                              className="d-flex jc-start ai-center"
                            >
                              <div className="d-flex flex-column">
                                <span className="f-12">Title</span>
                                <span className="f-18">{item.title}</span>
                              </div>
                            </Col>
                            <Col span={11} className="d-flex jc-start ai-start">
                              {item.description && (
                                <div className="d-flex flex-column">
                                  <span className="f-12">Description</span>
                                  <span className="f-18">
                                    {item.description}
                                  </span>
                                </div>
                              )}
                            </Col>
                            <Col span={2} className="d-flex jc-end ai-center">
                              {item.actions}
                            </Col>
                          </Row>
                          <Divider />
                          <Row className="info">
                            <Col span={8} className="d-flex jc-start ai-center">
                              <div className="d-flex jc-center ai-center">
                                <div>
                                  <FiCalendar className="mr-10" />
                                </div>
                                <div className="d-flex flex-column">
                                  <span className="f-12">Date</span>
                                  <span className="f-16 semi-bold">
                                    {item.date}
                                  </span>
                                </div>
                              </div>
                            </Col>
                            <Col span={8} className="d-flex jc-start ai-center">
                              <div className="d-flex jc-center ai-center">
                                <div>
                                  <FiClock className="mr-10" />
                                </div>
                                <div className="d-flex flex-column">
                                  <span className="f-12">Hour</span>
                                  <span className="f-16 semi-bold">
                                    {item.hourStart} - {item.hourEnd}
                                  </span>
                                </div>
                              </div>
                            </Col>
                            <Col span={8} className="d-flex jc-start ai-center">
                              {item.location_name && (
                                <div className="d-flex jc-center ai-center">
                                  <div>
                                    <FiMapPin className="mr-10" />
                                  </div>
                                  <div className="d-flex flex-column">
                                    <span className="f-12">Location</span>
                                    <span className="f-16 semi-bold">
                                      {item.location_name}
                                    </span>
                                  </div>
                                </div>
                              )}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    );
                  })}
                </div>
                <div className="d-flex jc-center ai-center">
                  <Pagination
                    onChange={handleChangePage}
                    pageSize={itemsPerPage}
                    defaultCurrent={1}
                    current={currentPage}
                    total={data.length}
                  />
                </div>
              </div>
            ) : (
              <Row className="d-flex jc-center ai-center empty_data">
                <Col
                  span={24}
                  className="d-flex flex-column jc-center ai-center"
                >
                  <Empty style={{ color: "#c1c1c1" }} />
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Spin>
    </div>
  );
}

export default Program;
