import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { ConfigProvider, Spin } from "antd";
import axios from "axios";

import Program from "./pages/Program/Program.js";
import Users from "./pages/Users/Users.js";
import Locations from "./pages/Locations/Locations.js";
import Login from "./pages/Login/Login";
import Notifications from "./pages/Notifications/Notifications";
import Settings from "./pages/Settings/Settings";
import PageNotFound from "./pages/PageNotFound/PageNotFound";

import Main from "./layout/Main/Main.js";

import "./assets/styles/global.css";
import "./index.css";
import api from "./utils/api.js";
import Voting from "./pages/Voting/Voting.js";
import Teams from "./pages/Teams/Teams.js";
import PostEventEvaluation from "./pages/PostEventEvaluation/PostEventEvaluation.js";
import Transfers from "./pages/Transfers/Transfers.js";
import Faqs from "./pages/Faqs/Faqs.js";
import OfficialAgency from "./pages/OfficalAgency/OfficialAgency.js";
import Challenges from "./pages/Challenges/Challenges.js";

api.axiosCreate();

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isOpenLogout, setIsOpenLogout] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  function handleLogout() {
    localStorage.removeItem("token");
    navigate("/login");
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "rgba(131, 0, 81, 1)",
          fontFamily: "Montserrat",
        },
      }}
    >
      <div
        className={`App ${
          window.location.pathname.includes("/admin") ? "admin" : ""
        }`}
      >
        <Routes>
          <Route exact path="/login" element={<Login />} />
          <Route element={<Main handleLogout={handleLogout} />}>
            <Route
              index
              exact
              path="/"
              element={<Navigate to={`/program`} replace />}
            />
            <Route
              exact
              path="/login"
              element={<Navigate to={`/program`} replace />}
            />
            <Route exact path="/program" element={<Program />} />
            <Route exact path="/challenges" element={<Challenges />} />
            <Route exact path="/users" element={<Users />} />
            <Route exact path="/locations" element={<Locations />} />
            <Route exact path="/voting" element={<Voting />} />
            <Route exact path="/teams" element={<Teams />} />
            <Route exact path="/evaluation" element={<PostEventEvaluation />} />
            <Route exact path="/notifications" element={<Notifications />} />
            <Route exact path="/settings" element={<Settings />} />
            <Route exact path="/transfers" element={<Transfers />} />
            <Route exact path="/faqs" element={<Faqs />} />
            <Route exact path="/pco" element={<OfficialAgency />} />
            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Routes>
      </div>
    </ConfigProvider>
  );
}

export default App;
