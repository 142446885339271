import {
  Row,
  Col,
  Modal,
  Button,
  Input,
  Form,
  notification,
  DatePicker,
} from "antd";
import { useEffect, useState } from "react";
import axios from "axios";
import "react-quill/dist/quill.snow.css";
import config from "../../utils/config.js";
import endpoints from "../../utils/endpoints.js";
import dayjs from "dayjs";

export default function Update({ data, open, close }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    data.date_time = data.date_time ? dayjs(data.date_time) : null;
    form.setFieldsValue(data);
  }, [data]);

  function handleSubmit(values) {
    setIsButtonLoading(true);
    axios
      .post(endpoints.notification.update, {
        data: { oldValues: data, values: values },
      })
      .then((resData) => {
        close();
        setIsButtonLoading(false);
        form.resetFields();
        notification.success({
          message: "Yeah!",
          description: "Notificação foi editada com sucesso!",
        });
      })
      .catch((error) => {
        console.log(error);
        setIsButtonLoading(false);
        notification.error({
          message: "Oops!",
          description: "Algo de errado aconteceu! Tente novamente mais tarde.",
        });
      });
  }

  function handleWarningMissingFields() {
    notification.error({
      message: `Campos por preencher`,
      description: "Faltam alguns campos para preencher!",
    });
  }

  function handleClose() {
    close();
    form.resetFields();
  }

  return (
    <Modal
      className="modal-antd-notification"
      title={`Editar notificação`}
      open={open}
      onCancel={handleClose}
      footer={[
        <Button
          className="mr-20"
          size="large"
          type="primary"
          onClick={form.submit}
          loading={isButtonLoading}
        >
          Guardar
        </Button>,
        <Button disabled={isButtonLoading} size="large" onClick={handleClose}>
          Cancelar
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        onFinishFailed={handleWarningMissingFields}
      >
        <Row>
          <Col span={24}>
            <Form.Item hidden name="id">
              <Input />
            </Form.Item>
            <Form.Item
              name="title"
              label="Título"
              rules={[
                { required: true, message: "Este é um campo obrigatório" },
              ]}
            >
              <Input size="large" placeholder="Título..." />
            </Form.Item>
            <Form.Item name="description" label="Descrição">
              <Input size="large" placeholder="Descrição..." />
            </Form.Item>
            {/*<Form.Item name="date_time" label="Data">
              <DatePicker className="w-100" showTime />
            </Form.Item>*/}
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
