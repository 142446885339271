import React, { useEffect, useState } from "react";
import { Button, Form, Drawer, message, Input, Row, Col, Select, Switch } from "antd";
import axios from "axios";
import { useForm } from "antd/es/form/Form";
import ReactQuill from "react-quill";

import endpoints from "../../utils/endpoints";
import "react-quill/dist/quill.snow.css";
import dayjs from "dayjs";

function Update({ data, open, close }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [programs, setPrograms] = useState([]);

  const [form] = useForm();

  useEffect(() => {
    if (open) {
      form.setFieldsValue(data);
      axios
        .get(endpoints.user.read)
        .then((res) => {
          setUsers(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [open]);

  function handleClose() {
    form.resetFields();
    setIsButtonLoading(false);
    close();
  }

  function handleSubmit(values) {
    setIsButtonLoading(true);
    let removedUsers = [];
    let addedUsers = [];

    if (JSON.stringify(values.users) !== JSON.stringify(data.users)) {
      removedUsers = data.users.filter((x) => !values.users.includes(x));
      addedUsers = values.users.filter((x) => !data.users.includes(x));
    }

    axios
      .post(endpoints.teams.update, {
        data: {
          team: {
            id: values.id,
            name: values.name,
            description: values.description,
          },
          users: {
            added: addedUsers,
            removed: removedUsers,
          },
        },
      })
      .then((res) => {
        setTimeout(() => {
          setIsButtonLoading(false);
          handleClose();
          form.resetFields();
        }, 1000);
      })
      .catch((error) => {
        setIsButtonLoading(false);
        console.error(error);
      });
  }

  return (
    <Drawer
      key={`drawer-update-team`}
      title="Edit team"
      width={800}
      onClose={handleClose}
      open={open}
      bodyStyle={{
        paddingBottom: 80,
      }}
      extra={[
        <Button size="large" disabled={isButtonLoading} className="mr-10" onClick={handleClose}>
          Cancel
        </Button>,
        <Button size="large" loading={isButtonLoading} type="primary" onClick={form.submit}>
          Update
        </Button>,
      ]}
    >
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>

        <Form.Item
          label="Nome"
          name="name"
          rules={[
            {
              required: true,
              message: "This field is required",
            },
          ]}
        >
          <Input placeholder="Name..." size="large" />
        </Form.Item>
        <Form.Item name="description" label="Description">
          <Input placeholder="Description..." size="large" />
        </Form.Item>

        <Form.Item
          label="Users"
          name="users"
          rules={[
            {
              required: true,
              message: "This field is required",
            },
          ]}
        >
          <Select
            mode="multiple"
            placeholder="Type..."
            options={users.map((item) => ({
              value: item.id,
              label: item.name,
            }))}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
            size="large"
            getPopupContainer={(trigger) => trigger.parentNode}
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
}

export default Update;
