import {
  Row,
  Col,
  Button,
  Input,
  DatePicker,
  TimePicker,
  Select,
  Radio,
  Form,
  notification,
  Drawer,
  Switch,
  Checkbox,
} from "antd";
import { useEffect, useState } from "react";
import axios from "axios";
import TextArea from "antd/lib/input/TextArea";
import dayjs from "dayjs";
import ReactQuill from "react-quill";

import config from "../../utils/config.js";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";

import "react-quill/dist/quill.snow.css";
import endpoints from "../../utils/endpoints.js";

const { Option } = Select;
const { RangePicker } = TimePicker;

function DrawerEditProgram({
  data,
  open,
  close,
  optionsType,
  moderators,
  speakers,
}) {
  const [moderatorsData, setModeratorsData] = useState([]);
  const [speakersData, setSpeakersData] = useState([]);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isOpenAddModerator, setIsOpenAddModerator] = useState(false);
  const [isOpenAddSpeaker, setIsOpenAddSpeaker] = useState(false);
  const [locations, setLocations] = useState([]);

  const [form] = Form.useForm();

  useEffect(() => {
    if (Object.keys(data).length > 0 && open) {
      data.date = dayjs(data.date);
      data.duration = [
        dayjs(data.hour_start, "HH:mm:ss"),
        dayjs(data.hour_end, "HH:mm:ss"),
      ];

      console.log(data);
      form.setFieldsValue(data);
      setModeratorsData(moderators);
      setSpeakersData(speakers);

      handleGetData();
    }
  }, [data]);

  function handleGetData() {
    axios
      .get(endpoints.location.read)
      .then((res) => {
        setLocations(res.data);
      })
      .catch((err) => {
        setLocations(err);
      });
  }

  function handleSubmit(values) {
    setIsButtonLoading(true);

    values.hour_start = dayjs(values.duration[0]).format("HH:mm:ss");
    values.hour_end = dayjs(values.duration[1]).format("HH:mm:ss");
    values.date = dayjs(values.date).format("YYYY-MM-DD");
    delete values.duration;

    axios
      .post(endpoints.program.update, {
        data: values,
      })
      .then((response) => {
        setTimeout(() => {
          close();
          setIsButtonLoading(false);
          form.resetFields();
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
        setIsButtonLoading(false);
      });
  }

  function handleWarningMissingFields() {
    notification.error({
      message: `Campos por preencher`,
      description: "Faltam alguns campos para preencher!",
    });
  }

  function handleCloseDrawer() {
    close();
    setIsButtonLoading(false);
    form.resetFields();
  }

  return (
    <Drawer
      width={800}
      className="drawer-antd-program"
      title={`Update program`}
      open={open}
      onClose={handleCloseDrawer}
      maskClosable={false}
      extra={[
        <Button
          className="mr-10"
          size="large"
          type="primary"
          onClick={form.submit}
          loading={isButtonLoading}
          disabled={isButtonLoading}
        >
          Update
        </Button>,
        <Button size="large" onClick={handleCloseDrawer}>
          Cancel
        </Button>,
      ]}
    >
      <Form
        form={form}
        id="add-program-form"
        layout="vertical"
        onFinish={handleSubmit}
        onFinishFailed={handleWarningMissingFields}
      >
        <Row gutter={[24, 0]}>
          <Col span={24}>
            <Form.Item name="id" hidden>
              <Input />
            </Form.Item>
            <Form.Item
              name="title"
              label="Título"
              rules={[
                { required: true, message: "Este é um campo obrigatório" },
              ]}
            >
              <Input size="large" placeholder="Title..." />
            </Form.Item>
            <Form.Item name="description" label="Description">
              <ReactQuill
                placeholder="Description"
                key="description"
                theme="snow"
              />
            </Form.Item>
            <Form.Item name="id_location" label="Location">
              <Select
                placeholder="Location..."
                options={locations.map((item) => {
                  return { value: item.id, label: item.name };
                })}
                size="large"
              />
            </Form.Item>
            <Row gutter={[24]}>
              <Col span={12}>
                <Form.Item
                  name="date"
                  label="Data"
                  rules={[
                    { required: true, message: "Este é um campo obrigatório" },
                  ]}
                >
                  <DatePicker key={"data"} style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="duration"
                  label="Duração"
                  rules={[
                    { required: true, message: "Este é um campo obrigatório" },
                  ]}
                >
                  <RangePicker
                    format={"HH:mm"}
                    key={"hora"}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>
            {/*<Row gutter={[24]}>
              <Col span={4}>
                <Form.Item
                  name="votation"
                  label="Votation"
                  valuePropName="checked"
                >
                  <Switch size="large">Votation</Switch>
                </Form.Item>
              </Col>
              <Col span={20}>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.votation !== currentValues.votation
                  }
                >
                  {({ getFieldValue }) => {
                    if (getFieldValue("votation")) {
                      return (
                        <Form.Item name="votation_link" label="Votation link ">
                          <Input size="large" placeholder="Votation link..." />
                        </Form.Item>
                      );
                    }
                  }}
                </Form.Item>
              </Col>
            </Row>*/}

            <Form.Item name="rating" label="Rating" valuePropName="checked">
              <Switch size="large">Rating</Switch>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
}

export default DrawerEditProgram;
