import { Row, Col, Modal, Button, Input, Form, notification } from "antd";
import { useEffect, useState } from "react";
import axios from "axios";
import { DeleteOutlined } from "@ant-design/icons";

import endpoints from "../../utils/endpoints.js";

function ModalDeleteProgram({ data, open, close }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [form] = Form.useForm();

  function handleDelete() {
    console.log(data);
    setIsButtonLoading(true);

    axios
      .post(endpoints.challenges.delete, {
        data: data,
      })
      .then((res) => {
        close();
        setIsButtonLoading(false);
        form.resetFields();
        notification.success({
          message: "Yeah!",
          description: "Challenge was deleted with success!",
        });
      })
      .catch((error) => {
        console.log(error);
        setIsButtonLoading(false);
        notification.error({
          message: "Oops!",
          description: "Algo de errado aconteceu! Tente novamente mais tarde.",
        });
      });
  }

  function handleCloseModal() {
    close();
    form.resetFields();
  }

  return (
    <Modal
      title={`Delete program`}
      open={open}
      onCancel={handleCloseModal}
      footer={[
        <Button
          danger
          icon={<DeleteOutlined />}
          size="large"
          type="primary"
          onClick={handleDelete}
          loading={isButtonLoading}
          disabled={isButtonLoading}
        >
          Delete
        </Button>,
        <Button size="large" onClick={handleCloseModal}>
          Cancel
        </Button>,
      ]}
    >
      <Row gutter={[24, 0]}>
        <Col span={24}>
          <p className="f-16">Are you sure that you wann delete the program:</p>
          <div
            className="f-20 bold"
            dangerouslySetInnerHTML={{ __html: data.title }}
          ></div>
        </Col>
      </Row>
    </Modal>
  );
}

export default ModalDeleteProgram;
