import React, { useState, useEffect } from "react";
import { Col, Row, Button, Table, Input, Form, Avatar, Tooltip, Switch } from "antd";
import { EditOutlined, DeleteOutlined, ReloadOutlined } from "@ant-design/icons";
import axios from "axios";
import dayjs from "dayjs";

import Update from "../../components/PostEventEvaluation/Update";
import Create from "../../components/PostEventEvaluation/Create";
import Delete from "../../components/PostEventEvaluation/Delete";
import endpoints from "../../utils/endpoints";

import "./PostEventEvaluation.css";
import { FiMapPin } from "react-icons/fi";
import Read from "../../components/PostEventEvaluation/Read";

const PostEventEvaluation = () => {
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isOpenRead, setIsOpenRead] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const [formSetting] = Form.useForm();

  const columns = [
    {
      title: "Question",
      dataIndex: "question",
    },
    {
      title: "Type",
      dataIndex: "type",
    },
    {
      title: "Position",
      dataIndex: "position",
    },
    {
      title: "",
      dataIndex: "actions",
    },
  ];

  useEffect(() => {
    handleGetData();
    handleGetSettings();
  }, []);

  function handleGetData() {
    setIsLoading(true);
    axios
      .get(endpoints.evaluation.read)
      .then((res) => {
        console.log(res);
        if (res.data.length > 0) {
          setData(res.data[0]);
          setAnswers(res.data[1]);
          handlePrepareData(res.data[0]);
        } else {
          setIsLoading(false);
          setData([]);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  }

  function handleGetSettings() {
    axios
      .get(endpoints.settings.read)
      .then((res) => {
        console.log(res);
        let settingEvaluation = res.data.filter((item) => item.name === "evaluation_is_active")[0];
        console.log(settingEvaluation);
        formSetting.setFieldsValue(JSON.parse(settingEvaluation.json));
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function handlePrepareData(data, usersData) {
    setIsLoading(true);
    let aux = [];
    for (let i = 0; i < data.length; i++) {
      let auxObj = {
        id: data[i].id,
        question: data[i].question,
        type: data[i].type,
        position: data[i].position,
        actions: (
          <div className="d-flex jc-end">
            <Button onClick={() => handleOpenUpdate(data[i])}>
              <EditOutlined />
            </Button>
            {!data[i].is_admin && (
              <Button className="ml-10" onClick={() => handleOpenDelete(data[i])}>
                <DeleteOutlined />
              </Button>
            )}
          </div>
        ),
        full_data: data[i],
      };

      aux.push(auxObj);
    }

    setIsLoading(false);
    setTableData(aux);
  }

  function handleOpenCreate() {
    setIsOpenCreate(true);
  }

  function handleCloseCreate() {
    setIsOpenCreate(false);
    handleGetData();
  }

  function handleOpenUpdate(data) {
    setSelectedData(data);
    setIsOpenUpdate(true);
  }

  function handleCloseUpdate() {
    setIsOpenUpdate(false);
    handleGetData();
  }

  function handleOpenDelete(data) {
    console.log(data);
    setSelectedData(data);
    setIsOpenDelete(true);
  }

  function handleCloseDelete() {
    setSelectedData({});
    setIsOpenDelete(false);
    handleGetData();
  }

  function handleCloseRead() {
    setIsOpenRead(false);
  }

  function handleOpenRead() {
    setIsOpenRead(true);
  }

  function handleSearch(e) {
    console.log(e);
    let searchString = e.search;
    let filterData = data.filter((item) => item.email.includes(searchString) || item.name.toLowerCase().includes(searchString));
    handlePrepareData(filterData);
  }

  function handleActivate(values) {
    console.log(values);
    axios
      .post(endpoints.settings.update, {
        data: { name: "evaluation_is_active", values: values },
      })
      .then((res) => {
        console.log(res);
        handleGetSettings();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div className="dashboard_page">
      <Row>
        <Create open={isOpenCreate} close={handleCloseCreate} />
        <Update open={isOpenUpdate} close={handleCloseUpdate} data={selectedData} />
        <Delete open={isOpenDelete} close={handleCloseDelete} data={selectedData} />
        <Read open={isOpenRead} close={handleCloseRead} data={answers} />
        <Col span={24}>
          <Row className="title_row mb-20 dashboard_title_row">
            <Col span={6}>
              <h3 className="mt-0 mb-0">Post Event Evaluation</h3>
            </Col>
            <Col span={18}>
              <div className="d-flex jc-end ai-center">
                <div className="mr-40">
                  <Form form={formSetting} onValuesChange={handleActivate}>
                    <Form.Item name="is_active" className="mb-0" valuePropName="checked" label="Activate">
                      <Switch size="large" />
                    </Form.Item>
                  </Form>
                </div>
                <Form onValuesChange={handleSearch} style={{ maxWidth: 400, borderRadius: 0 }}>
                  <Form.Item name="search" className="mb-0 mr-10">
                    <Input size="large" className="" style={{ maxWidth: 400, borderRadius: 0 }} placeholder="Search..." />
                  </Form.Item>
                </Form>
                <Button className="mr-10" size="large" onClick={handleGetData}>
                  <ReloadOutlined />
                </Button>
                <Button className="mr-10" size="large" onClick={handleOpenCreate}>
                  Create
                </Button>
                <Button size="large" onClick={handleOpenRead}>
                  Answers
                </Button>
              </div>
            </Col>
          </Row>
          <Row className="dashboard_content_row">
            <Col span={24}>
              <Table loading={isLoading} columns={columns} dataSource={tableData} />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default PostEventEvaluation;
