import React, { useState, useEffect } from "react";
import { Col, Row, Button, Table, Input, Form, Avatar, Tooltip, Pagination } from "antd";
import { EditOutlined, DeleteOutlined, ReloadOutlined } from "@ant-design/icons";
import axios from "axios";
import dayjs from "dayjs";

import Update from "../../components/Teams/Update";
import Create from "../../components/Teams/Create";
import Delete from "../../components/Teams/Delete";
import endpoints from "../../utils/endpoints";

import "./Teams.css";
import { FiMapPin } from "react-icons/fi";
import config from "../../utils/config";

const Teams = () => {
  const [data, setData] = useState([]);
  const [users, setUsers] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",

      sorter: (a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0),
    },
    {
      title: "Description",
      dataIndex: "description",
    },
    {
      title: "Users",
      dataIndex: "users",
    },
    {
      title: "",
      dataIndex: "actions",
    },
  ];

  useEffect(() => {
    handleGetData();
  }, []);

  function handleGetData() {
    setIsLoading(true);
    axios
      .get(endpoints.teams.read)
      .then((res) => {
        console.log(res);
        setData(res.data[0]);
        setUsers(res.data[1]);
        handlePrepareData(res.data[0], res.data[1]);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  }

  function handlePrepareData(data, usersData) {
    setIsLoading(true);
    let aux = [];
    for (let i = 0; i < data.length; i++) {
      let auxUsers = usersData.filter((u) => u.id_team === data[i].id);
      let users = [];
      auxUsers.forEach((element) => {
        users.push(element.id_user);
      });

      data[i] = { ...data[i], users: users };

      let auxObj = {
        id: data[i].id,
        name: data[i].name,
        description: data[i].description,
        users: (
          <span>
            <Avatar.Group
              maxCount={10}
              size="large"
              maxPopoverTrigger={""}
              maxStyle={{
                color: "#4A4C4D",
                backgroundColor: "#F3F6F9",
              }}
            >
              {auxUsers.map((item, index) => (
                <Tooltip title={item.user_name}>
                  <Avatar src={data[i].img ? `${config.server_ip}media/${data[i].img}` : `${config.server_ip}media/User-default.svg`} />
                </Tooltip>
              ))}
            </Avatar.Group>
          </span>
        ),
        actions: (
          <div className="d-flex jc-end">
            <Button onClick={() => handleOpenUpdate(data[i])}>
              <EditOutlined />
            </Button>
            {!data[i].is_admin && (
              <Button className="ml-10" onClick={() => handleOpenDelete(data[i])}>
                <DeleteOutlined />
              </Button>
            )}
          </div>
        ),
        full_data: data[i],
      };

      aux.push(auxObj);
    }

    setIsLoading(false);
    setTableData(aux);
  }

  function handleOpenCreate() {
    setIsOpenCreate(true);
  }

  function handleCloseCreate() {
    setIsOpenCreate(false);
    handleGetData();
  }

  function handleOpenUpdate(data) {
    setSelectedData(data);
    setIsOpenUpdate(true);
  }

  function handleCloseUpdate() {
    setIsOpenUpdate(false);
    handleGetData();
  }

  function handleOpenDelete(data) {
    setSelectedData(data);
    setIsOpenDelete(true);
  }

  function handleCloseDelete() {
    setSelectedData({});
    setIsOpenDelete(false);
    handleGetData();
  }

  function handleSearch(e) {
    console.log(e);
    let searchString = e.search;
    let filterData = data.filter((item) => item.name.toLowerCase().includes(searchString.toLowerCase()));
    console.log(filterData);
    handlePrepareData(filterData, users);
  }

  return (
    <div className="dashboard_page">
      <Row>
        <Create open={isOpenCreate} close={handleCloseCreate} />
        <Update open={isOpenUpdate} close={handleCloseUpdate} data={selectedData} />
        <Delete open={isOpenDelete} close={handleCloseDelete} data={selectedData} />
        <Col span={24}>
          <Row className="title_row mb-20 dashboard_title_row">
            <Col span={6}>
              <h3 className="mt-0 mb-0">Teams</h3>
            </Col>
            <Col span={18}>
              <div className="d-flex jc-end ai-center">
                <Form onValuesChange={handleSearch} style={{ maxWidth: 400, borderRadius: 0 }}>
                  <Form.Item name="search" className="mb-0 mr-10">
                    <Input size="large" className="" style={{ maxWidth: 400, borderRadius: 0 }} placeholder="Search..." />
                  </Form.Item>
                </Form>
                <Button className="mr-10" size="large" onClick={handleGetData}>
                  <ReloadOutlined />
                </Button>
                <Button type="primary" size="large" onClick={handleOpenCreate}>
                  Create
                </Button>
              </div>
            </Col>
          </Row>
          <Row className="dashboard_content_row">
            <Col span={24}>
              <Table loading={isLoading} columns={columns} dataSource={tableData} pagination={{ pageSize: 20 }} />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Teams;
