import React, { Component, useState } from "react";
import {
  Layout,
  Button,
  Row,
  Col,
  Typography,
  Form,
  Input,
  Switch,
  notification,
} from "antd";
import {
  RadiusBottomleftOutlined,
  RadiusBottomrightOutlined,
  RadiusUpleftOutlined,
  RadiusUprightOutlined,
} from "@ant-design/icons";
import axios from "axios";
import "./Login.css";
import encontroRenalLogo from "../../assets/images/logo-branco-encontro-renal.svg";
import spnLogo from "../../assets/images/icon-SPN-splash.svg";
import apedtLogo from "../../assets/images/APEDT-grey-logo.svg";
import config from "../../utils/config";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/Loading/Loading";
import endpoints from "../../utils/endpoints";
import api from "../../utils/api";

const { Title } = Typography;
const { Content } = Layout;

export default function SignIn() {
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    let token = localStorage.getItem("token");
    console.log(token);
    if (token) {
      axios
        .post(endpoints.auth.verifyToken, {
          data: token,
        })
        .then(async (res) => {
          console.log(res);
          if (res.data.token_valid) {
            localStorage.setItem("token", res.data.token);
            await api.axiosInit(token);
            navigate("/program");
          } else {
            setIsLoading(false);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  function onFinish(values) {
    setIsButtonLoading(true);
    axios
      .post(`${config.server_ip}auth/login`, {
        data: values,
      })
      .then((res) => {
        console.log(res);
        if (res.data.user_exists && res.data.user_password) {
          let userData = res.data.user;
          if (userData.is_admin) {
            localStorage.setItem("token", res.data.token);
            notification.success({
              message: `Login efetuado`,
              description: "Login efetuado com sucesso!",
            });
            setTimeout(() => {
              setIsButtonLoading(false);
              navigate("/program");
            }, 1000);
          } else {
            setIsButtonLoading(false);
            notification.error({
              message: `Sem permissões`,
              description: "Não tem permissões para entrar no backoffice",
            });
          }
        } else {
          setIsButtonLoading(false);
          if (!res.data.user_exists) {
            notification.error({
              message: `E-mail errado`,
              description:
                "Este e-mail não existe na base dados, por favor verifique se o e-mail está correto!",
            });
          } else if (!res.data.user_password) {
            notification.error({
              message: `Password errada`,
              description: "Por favor verifique a sua password!",
            });
          }
        }
      })
      .catch((err) => {
        setIsButtonLoading(false);
        console.log(err);
        notification.error({
          message: `Erro`,
          description:
            "Alguma coisa de errado aconteceu, tente novamente mais tarde!",
        });
      });
  }

  function onFinishFailed(errorInfo) {
    console.log("Failed:", errorInfo);
    notification.error({
      message: `Alguma coisa está em falta!`,
      description:
        "Por favor verifique se preencheu todos os campos necessários!",
    });
  }

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Layout className="layout-default layout-signin">
          <Content className="signin">
            <Row gutter={[24, 0]} justify="space-between">
              {/*<Col
                xs={{ span: 24 }}
                lg={{ span: 8 }}
                md={{ span: 8 }}
              >
                <div className="sigin-div-image">
                </div>
      </Col>*/}
              <Col
                xs={{ span: 24 }}
                lg={{ span: 24 }}
                md={{ span: 24 }}
                className="form-col"
              >
                <Title className="mb-15">Login</Title>
                <Title className="font-regular text-muted" level={5}>
                  Insira o seu e-mail e password para fazer login
                </Title>
                <Form
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  layout="vertical"
                  className="row-col"
                >
                  <Form.Item
                    className="username"
                    label="E-mail"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Por favor insira o seu e-mail!",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="E-mail" />
                  </Form.Item>

                  <Form.Item
                    className="password"
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Por favor insira a sua password!",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      type="password"
                      placeholder="Password"
                    />
                  </Form.Item>
                  <Form.Item className="button-signin mt-40">
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ width: "100%" }}
                      size="large"
                      loading={isButtonLoading}
                    >
                      Entrar
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Content>
        </Layout>
      )}
    </>
  );
}
