import { Row, Col, Button, Divider, Pagination, Spin, Empty } from "antd";
import { DeleteOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import axios from "axios";

import Create from "../../components/Notifications/Create";
import Update from "../../components/Notifications/Update";
import Delete from "../../components/Notifications/Delete";

import "./Notifications.css";
import endpoints from "../../utils/endpoints";
import dayjs from "dayjs";

export default function Notifications() {
  const [notifications, setNotifications] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedNotification, setSelectedNotification] = useState({});
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(5);

  useEffect(() => {
    handleGetNotifications();
  }, []);

  function handleGetNotifications() {
    setIsLoading(true);
    axios
      .get(endpoints.notification.read)
      .then((response) => {
        let notificationsData = [];
        for (let i = 0; i < response.data.length; i++) {
          let arrayData = response.data[i];
          let aux = {
            key: i + 1,
            id: arrayData.id,
            title: arrayData.title,
            description: arrayData.description,
            date_time: arrayData.date_time
              ? dayjs(arrayData.date_time).format("DD-MM-YYYY HH:mm:ss")
              : "Sem data",
            actions: !arrayData.sended ? (
              <div className="actions-buttons-div">
                <DeleteOutlined
                  className={`edit-action ${
                    arrayData.sended ? "disabled" : ""
                  }`}
                  onClick={() => handleOpenDelete(arrayData)}
                />
              </div>
            ) : (
              <CheckCircleOutlined className="edit-action disabled" />
            ),
          };

          notificationsData.push(aux);
        }

        setTableData(notificationsData);
        setNotifications(response.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }

  function handleChangePage(e) {
    setCurrentPage(e);
    if (e <= 1) {
      setMinValue(0);
      setMaxValue(itemsPerPage);
    } else {
      let newMinValue = itemsPerPage * (e - 1);
      let newMaxValue = newMinValue + itemsPerPage;
      setMinValue(newMinValue);
      setMaxValue(newMaxValue);
    }
  }

  function handleOpenCreate() {
    setIsOpenAdd(true);
  }

  function handleCloseCreate() {
    setIsOpenAdd(false);
    handleGetNotifications();
  }

  function handleOpenUpdate(data) {
    setSelectedNotification(data);
    setIsOpenEdit(true);
  }

  function handleCloseUpdate() {
    setIsOpenEdit(false);
    setSelectedNotification({});
    handleGetNotifications();
  }

  function handleOpenDelete(data) {
    setSelectedNotification(data);
    setIsOpenDelete(true);
  }

  function handleCloseDelete() {
    setIsOpenDelete(false);
    setSelectedNotification({});
    handleGetNotifications();
  }

  return (
    <div className="dashboard_page">
      <Create open={isOpenAdd} close={handleCloseCreate} />
      <Update
        data={selectedNotification}
        open={isOpenEdit}
        close={handleCloseUpdate}
      />
      <Delete
        data={selectedNotification}
        open={isOpenDelete}
        close={handleCloseDelete}
      />
      <Row className="title_row mb-20 dashboard_title_row">
        <Col span={12} className="d-flex jc-start ai-center">
          <h3 className="mt-0 mb-0">Notificações</h3>
        </Col>
        <Col span={12} className="d-flex jc-end ai-center">
          <Button type="primary" size="large" onClick={handleOpenCreate}>
            Adicionar
          </Button>
        </Col>
      </Row>
      <Divider />
      <Spin spinning={isLoading}>
        <Row className="dashboard_content_row">
          <Col xs="24" xl={24}>
            {tableData.length > 0 ? (
              <div className="d-flex flex-column jc-sb h-100">
                <div>
                  {tableData.slice(minValue, maxValue).map((item) => {
                    return (
                      <Row className="table_item">
                        <Col span={11}>
                          <div className="d-flex flex-column">
                            <span className="f-12">Título</span>
                            <span className="f-18 semi-bold mt-5">
                              {item.title}
                            </span>
                          </div>
                        </Col>
                        <Col span={11} className="d-flex jc-start ai-center">
                          <div className="d-flex flex-column">
                            <span className="f-12">Descrição</span>
                            <span className="d-flex ai-center mt-5">
                              {item.description}
                            </span>
                          </div>
                        </Col>
                        {/*<Col span={7} className="d-flex jc-start ai-center">
                          <div className="d-flex flex-column">
                            <span className="f-12">Data</span>
                            <span className="d-flex ai-center">
                              {item.date_time}
                            </span>
                          </div>
                        </Col>*/}
                        <Col span={2} className="d-flex jc-end ai-center">
                          {item.actions}
                        </Col>
                      </Row>
                    );
                  })}
                </div>
                <div className="d-flex jc-center ai-center">
                  <Pagination
                    onChange={handleChangePage}
                    pageSize={itemsPerPage}
                    defaultCurrent={1}
                    current={currentPage}
                    total={notifications.length}
                  />
                </div>
              </div>
            ) : (
              <Empty description="This table is empty" />
            )}
          </Col>
        </Row>
      </Spin>
    </div>
  );
}
