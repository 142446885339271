import {
  Row,
  Col,
  Button,
  Input,
  DatePicker,
  TimePicker,
  Select,
  Radio,
  Form,
  notification,
  Drawer,
  Switch,
  Checkbox,
  Upload,
  Image,
  InputNumber,
  Divider,
} from "antd";
import { useEffect, useState } from "react";
import axios from "axios";
import TextArea from "antd/lib/input/TextArea";
import dayjs from "dayjs";
import ReactQuill from "react-quill";
import Dragger from "antd/es/upload/Dragger";
import QRCode from "qrcode";
import config from "../../utils/config.js";
import "react-quill/dist/quill.snow.css";
import {
  ArrowUpOutlined,
  DeleteOutlined,
  InboxOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import endpoints from "../../utils/endpoints.js";

const { Option } = Select;
const { RangePicker } = TimePicker;

export default function Create({ open, close }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [form] = Form.useForm();

  function handleSubmit(values) {
    console.log(values);
    setIsButtonLoading(true);

    axios
      .post(endpoints.challenges.create, {
        data: values,
      })
      .then((res) => {
        setTimeout(() => {
          close();
          setIsButtonLoading(false);
          form.resetFields();
          notification.success({
            message: "Yeah!",
            description: "Challenge created with success",
          });
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
        setIsButtonLoading(false);
        notification.error({
          message: "Oops!",
          description: "Something wrong happened! Try again later.",
        });
      });
  }

  function handleWarningMissingFields() {
    notification.error({
      message: `Campos por preencher`,
      description: "Faltam alguns campos para preencher!",
    });
  }

  function handleCloseDrawer() {
    close();
    setIsButtonLoading(false);
    form.resetFields();
  }

  async function handleAddQRCode(add) {
    let qrcodes = form.getFieldValue("qrcodes");

    let nameOfQRCode =
      Math.random().toString(36).substr(2) +
      "_" +
      dayjs().format("YYYYMMDDHHmmss");
    let QRCodeURL = await QRCode.toDataURL(nameOfQRCode);

    if (qrcodes) {
      qrcodes.push({ img: QRCodeURL, name: nameOfQRCode, points: null });
    } else {
      qrcodes = [{ img: QRCodeURL, name: nameOfQRCode, points: null }];
    }

    add();
    form.setFieldValue("qrcodes", qrcodes);
  }

  function handleRemove(remove, index) {
    remove(index);
  }

  return (
    <Drawer
      width={800}
      className="drawer-antd-program"
      title={`Create challenge`}
      open={open}
      onClose={handleCloseDrawer}
      maskClosable={false}
      extra={[
        <Button
          className="mr-10"
          size="large"
          type="primary"
          onClick={form.submit}
          loading={isButtonLoading}
        >
          Create
        </Button>,
        <Button
          size="large"
          onClick={handleCloseDrawer}
          disabled={isButtonLoading}
        >
          Cancel
        </Button>,
      ]}
    >
      <Form
        form={form}
        id="add-program-form"
        layout="vertical"
        onFinish={handleSubmit}
        onFinishFailed={handleWarningMissingFields}
      >
        <Row gutter={[24, 0]}>
          <Col span={24}>
            <Form.Item
              name="title"
              label="Title"
              rules={[
                { required: true, message: "Este é um campo obrigatório" },
              ]}
            >
              <Input size="large" placeholder="Title..." />
            </Form.Item>

            <Form.Item name="description" label="Description">
              <ReactQuill
                placeholder="Description"
                className="title_react_quill"
                theme="snow"
              />
            </Form.Item>
            <Form.Item
              name="type"
              label="Type"
              rules={[
                { required: true, message: "Este é um campo obrigatório" },
              ]}
            >
              <Select
                placeholder="Type..."
                options={[
                  { value: "qrcode", label: "QR Code" },
                  { value: "photo", label: "Photo" },
                  { value: "questions", label: "Perguntas" },
                ]}
                size="large"
              />
            </Form.Item>

            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.type !== currentValues.type ||
                prevValues.qrcodes !== currentValues.qrcodes
              }
            >
              {({ getFieldValue }) => {
                if (getFieldValue("type") === "qrcode") {
                  let QRCodeList = getFieldValue("qrcodes");
                  console.log(QRCodeList);
                  return (
                    <>
                      <Form.List name="qrcodes">
                        {(fields, { add, remove }, { errors }) => (
                          <>
                            <Row gutter={[24]} className="mb-40">
                              {fields.map(
                                ({ key, name, ...restField }, index) => (
                                  <Col
                                    span={6}
                                    className="form_list_options mb-20"
                                  >
                                    <div
                                      className={`preview_qrcode`}
                                      style={{
                                        backgroundImage: `url(${
                                          QRCodeList[name].img?.includes(
                                            "base64"
                                          )
                                            ? QRCodeList[name].img
                                            : `${config.server_ip}media/${QRCodeList[name].name}.jpg`
                                        })`,
                                      }}
                                    ></div>

                                    <Form.Item
                                      name={[name, "name"]}
                                      rules={[
                                        {
                                          required: true,
                                          message:
                                            "Este é um campo obrigatório",
                                        },
                                      ]}
                                    >
                                      <Input
                                        size="large"
                                        placeholder="QR Code"
                                        readOnly
                                      />
                                    </Form.Item>

                                    <Form.Item
                                      name={[name, "points"]}
                                      label="Points"
                                      rules={[
                                        {
                                          required: true,
                                          message:
                                            "Este é um campo obrigatório",
                                        },
                                      ]}
                                    >
                                      <InputNumber
                                        size="large"
                                        placeholder="5"
                                        min={0}
                                      />
                                    </Form.Item>
                                    <Button
                                      type="dashed"
                                      className="w-100"
                                      size="large"
                                      onClick={() => handleRemove(remove, name)}
                                    >
                                      <DeleteOutlined /> Apagar
                                    </Button>
                                  </Col>
                                )
                              )}
                              <Col
                                span={6}
                                className="d-flex jc-center ai-center mb-20"
                              >
                                <Button
                                  style={{ minHeight: "360px" }}
                                  className="w-100 h-100"
                                  size="large"
                                  type="dashed"
                                  onClick={() =>
                                    handleAddQRCode(add, fields.length - 1)
                                  }
                                >
                                  <PlusOutlined /> Adicionar
                                </Button>
                              </Col>
                            </Row>

                            <Form.ErrorList errors={errors} />
                          </>
                        )}
                      </Form.List>
                    </>
                  );
                }
              }}
            </Form.Item>

            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.type !== currentValues.type ||
                prevValues.qrcodes !== currentValues.qrcodes
              }
            >
              {({ getFieldValue }) => {
                if (getFieldValue("type") === "questions") {
                  return (
                    <Form.List
                      name="questions"
                      rules={[
                        {
                          validator: async (_, questions) => {
                            if (questions.length > 0) {
                              for (let i = 0; i < questions.length; i++) {
                                if (
                                  !questions[i] ||
                                  questions[i].answers.length < 2
                                ) {
                                  return Promise.reject(
                                    new Error(
                                      "A pergunta tem de ter obrigatoriamente 2 respostas."
                                    )
                                  );
                                }
                              }
                            } else {
                              return Promise.reject(
                                new Error(
                                  "Tem que adicionar alguma pergunta para conseguir submeter."
                                )
                              );
                            }
                          },
                        },
                      ]}
                    >
                      {(fields, { add, remove, move }, { errors }) => (
                        <>
                          {fields.map((field, index) => (
                            <>
                              <Form.Item
                                label={`Pergunta ${index + 1}`}
                                required={false}
                                key={`question_${field.key}`}
                                className="questions_form_item"
                              >
                                <div className="d-flex jc-center ai-center w-100">
                                  <Form.Item
                                    {...field}
                                    name={[index, "question"]}
                                    key={`question_${field.key}_${index}`}
                                    sizelarge
                                    rules={[
                                      {
                                        required: true,
                                        message: "Este campo é obrigatório",
                                      },
                                    ]}
                                    validateTrigger={["onChange", "onBlur"]}
                                    noStyle
                                  >
                                    <Input
                                      size="large"
                                      placeholder="Pergunta..."
                                    />
                                  </Form.Item>
                                  <div className="minw-40 d-flex ai-center">
                                    {fields.length > 1 ? (
                                      <MinusCircleOutlined
                                        className="f-22 ml-10"
                                        onClick={() => remove(field.name)}
                                      />
                                    ) : null}
                                  </div>
                                </div>
                              </Form.Item>
                              <Form.List name={[field.name, "answers"]}>
                                {(
                                  fields,
                                  { add, remove, move },
                                  { errors }
                                ) => (
                                  <>
                                    {fields.map((field, i) => (
                                      <div className="d-flex jc-center ai-center w-100">
                                        <Form.Item
                                          label="Resposta"
                                          {...field}
                                          name={[i, "answer"]}
                                          key={`answer_${field.name}_${field.key}`}
                                          sizelarge
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "Este campo é obrigatório",
                                            },
                                          ]}
                                          validateTrigger={[
                                            "onChange",
                                            "onBlur",
                                          ]}
                                          className="w-100 mr-10"
                                        >
                                          <Input
                                            size="large"
                                            placeholder="Resposta..."
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          label="Pontos"
                                          {...field}
                                          name={[i, "points"]}
                                          key={`answer_${field.name}_${field.key}_points`}
                                          sizelarge
                                          valuePropName="checked"
                                          initialValue={false}
                                          className="w-100 mr-10 ml-10"
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "Este campo é obrigatório",
                                            },
                                          ]}
                                        >
                                          <InputNumber size="large" />
                                        </Form.Item>
                                        <div className="minw-40 d-flex ai-center">
                                          {fields.length > 1 ? (
                                            <div>
                                              <MinusCircleOutlined
                                                className="f-22 mr-10"
                                                onClick={() =>
                                                  remove(field.name)
                                                }
                                              />
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>
                                    ))}
                                    <Form.Item>
                                      <Button
                                        type="dashed"
                                        onClick={() => add()}
                                        className="w-100"
                                        size="large"
                                        icon={<PlusOutlined />}
                                      >
                                        Adicionar resposta
                                      </Button>
                                    </Form.Item>
                                  </>
                                )}
                              </Form.List>
                              <Divider
                                style={{
                                  height: 2,
                                  backgroundColor: "rgba(0,0,0,0.2)",
                                }}
                              />
                            </>
                          ))}
                          <Form.Item>
                            <Button
                              type="dashed"
                              onClick={() => add()}
                              className="w-100"
                              size="large"
                              icon={<PlusOutlined />}
                            >
                              Adicionar pergunta
                            </Button>
                          </Form.Item>
                          <Form.ErrorList errors={errors} />
                        </>
                      )}
                    </Form.List>
                  );
                }
              }}
            </Form.Item>

            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.type !== currentValues.type ||
                prevValues.qrcodes !== currentValues.qrcodes
              }
            >
              {({ getFieldValue }) => {
                if (getFieldValue("type") === "photo") {
                  return (
                    <Form.Item name="points" label="Points">
                      <InputNumber min={0} size="large" placeholder="Points" />
                    </Form.Item>
                  );
                }
              }}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
}
